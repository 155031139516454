import logo from './logo.svg';
import SignIn from './SignIn.js'

import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignUp from './SignUp';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      
    <Route path="/" element={ <SignIn/>} />
    <Route path="/signup" element={<SignUp/>} />
   

    </Routes>
    </BrowserRouter>
  );
}

export default App;
